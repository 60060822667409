'use client'
import type { LottieProps } from 'react-lottie-player'
// import Spinner from './Spinner';
// import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import { Lottie } from './Lottie'
//     left: 0,

// const LoadLottie = styled(Lottie, {
//     position: 'fixed',
//     top: 0,
//     bottom: 0,
//     left: 0,
//     right: 0,
//     margin: 'auto',
//     maxHeight: '100vh',
//     maxWidth: '100vw',
//     height: '100vh',
// })
// const Lottie = dynamic(() => import('react-lottie-player'), {
//     loading: () => <p>Loading...</p>,
//     ssr: false
// })
export const PageLottie = ({ path, ...props }: { path: string } & LottieProps) => {
    return (
        <Lottie
            className="fixed top-0 bottom-0 left-0 right-0 max-h-screen max-w-[100vw] h-screen m-auto object-center object-contain"
            path={`/lotties/${path}.lottie`}
        />
    )
}
